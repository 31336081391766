const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

    (binding.modules['search'] = {
        c: () => require('@msdyn365-commerce-modules/search/dist/lib/modules/search/search'),
        $type: 'contentModule',
        da: [{name:'searchState',  path:'@msdyn365-commerce-modules/search-utilities/dist/lib/search-state-data-action', runOn: 1}],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'search',
        p: 'search',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/search/dist/lib/modules/search'
    });
        

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/search-utilities/dist/lib/search-state-data-action';
            let dataAction = require('@msdyn365-commerce-modules/search-utilities/dist/lib/search-state-data-action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|themes|reily-adventureworks|views|search'] = {
                c: () => require('partner/themes/reily-adventureworks/views/search.view.tsx'),
                cn: '__local__-__local__-search'
            };
viewDictionary['@msdyn365-commerce-modules|search|modules|search|search'] = {
                c: () => require('@msdyn365-commerce-modules/search/dist/lib/modules/search/search.view.js'),
                cn: '@msdyn365-commerce-modules-search-search'
            };
viewDictionary['@msdyn365-commerce-theme|adventureworks-theme-kit|modules|adventureworks|views|search'] = {
                c: () => require('@msdyn365-commerce-theme/adventureworks-theme-kit/dist/lib/modules/adventureworks/views/search.view.js'),
                cn: '@msdyn365-commerce-theme-adventureworks-theme-kit-search'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };